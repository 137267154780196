@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Lato-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./assets/fonts/Lato-Black.woff2) format('woff2');
}

:root {
  /* colors */
  --theme-color-ghost-white: #f6f8ff;
  --color-white: #fff;
  --gm-box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

body {
  background: var(--theme-color-ghost-white);
  margin: 0;
  font-family:
    'Lato',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h4 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

h5,
h6 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0; /* 3 */
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.gm-style .gm-style-iw-c {
  box-shadow: var(--gm-box-shadow) !important;
  padding: 22px !important;
  padding-top: 42px !important;
  max-width: 300px !important;
}

.gm-style .gm-style-iw-c button.gm-ui-hover-effect {
  top: 16px !important;
  right: 16px !important;
  font-size: 16px;
  font-weight: 900;
  opacity: 1;
}

.gm-style .gm-style-iw-c button.gm-ui-hover-effect:after {
  content: url(icons/close-24px.svg);
}

.gm-style a[href^="https://maps.google.com/maps"],
.gm-style .gm-style-iw-c button img,
.gm-style .gmnoprint .gmnoprint > div > div,
.gm-style-cc {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style > * div {
  outline: none !important;
}

.gm-style .gmnoprint > div > div {
  background: none !important;
  box-shadow: none !important;
}

.gm-style .gmnoprint .gmnoprint button {
  background: var(--color-white) !important;
  border-radius: 8px !important;
  box-shadow: var(--gm-box-shadow) !important;
  height: 32px !important;
  left: 2px !important;
  outline: none !important;
  top: 7px !important;
  width: 32px !important;
}

.gm-style .gmnoprint .gmnoprint button ~ button {
  top: 11px !important;
}

.gm-style .gmnoprint .gmnoprint button > img {
  height: 10px !important;
  width: 10px !important;
}

.Toastify__progress-bar--default {
  background: linear-gradient(90deg, #6b52e2 0%, #a48bfb 100%) !important;
}
